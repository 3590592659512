<template>
	<div>
		<Header title='设置个人头像' rightTitle='保存' @preservation='preservation'></Header>
		<img :src="image" style="width: 100%;height: 375px;margin-top: 10px;">
		<div style="text-align: center;">
			<van-uploader :after-read="afterRead">
				<div class="redBtn">从相册里选一张</div>
			</van-uploader>
		</div>
		<div style="text-align: center;">
			<van-uploader capture='camera' :after-read="afterRead">
				<div class="btn">拍一张</div>
			</van-uploader>
		</div>
	</div>
</template>

<script>
	import {
		upload
	} from '../../api/home.js'
	import {
		profile,
		index
	} from '../../api/mine.js'
	import {
		host
	} from '../../utils/host.js'
	import Header from '@/components/header.vue'
	export default {
		name: "updateHeader",
		data() {
			return {
				image: require('../../assets/image/mine/bigHeaderImg.png'),
				token: window.localStorage.getItem('token'),
				uploadImage: '',
				host: host,
			}
		},
		components: {
			Header
		},
		methods: {
			getTx() {
				let data = new FormData()
				data.append('token', this.token)
				index(data).then(res => {
					console.log('用户头像', res)
					if (res.data.avatar != '') {
						this.image = res.data.avatar
					}
				})
			},
			preservation() {
				let data = new FormData()
				data.append('token', this.token)
				data.append('avatar', this.uploadImage)
				profile(data).then(res => {
					console.log('保存个人信息', res)
					this.$toast('修改成功')
				})
			},
			afterRead(e) {
				this.image = e.content
				let data = new FormData()
				data.append('file', e.content)
				data.append('token', this.token)
				upload(data).then(res => {
					console.log('上传图片', res)
					this.uploadImage = res.data.url
				})
			}
		}
	}
</script>

<style scoped>
	.redBtn {
		width: 7.4rem;
		height: 40px;
		background: #DD1C25;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 20px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		margin: 0 auto;
		margin-top: 42px;
		letter-spacing: 2px;
	}

	.btn {
		width: 7.4rem;
		height: 40px;
		background: #FFFFFF;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 20px;
		line-height: 40px;
		text-align: center;
		color: #2B2A25;
		margin: 0 auto;
		margin-top: 24px;
		letter-spacing: 2px;
	}
</style>
